// nftContractConfig.js

// Import the ABI from a JSON file (assuming the ABI file is located in the 'abis' directory)
import nftContractABI from '../abi/nftContractABI.json';

// Define the contract address (replace '0x123...' with your contract's actual address)
export const nftContractAddress = '0xB8Bd78BE5a599DD48a0971d1D7CE627eE91407B3';

// Export the ABI
export const nftAbi = nftContractABI;
