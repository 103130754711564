'use client';
import * as React from 'react';
import { WagmiProvider} from 'wagmi';
import { getDefaultConfig, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { avalanche } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const config = getDefaultConfig({
  appName: 'Milly',
  projectId: 'b401b01e64b0e12f1675639aac63c6b8',
  chains: [avalanche]
});

const queryClient = new QueryClient();


export function Providers({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme({
          accentColor: 'white',
          accentColorForeground: 'black',
          borderRadius: 'large',
          fontStack: 'system',
          overlayBlur: 'small'
        })}
        modalSize="compact">
        {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}