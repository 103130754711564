import React from 'react';
import { Providers } from './providers';  // Adjust the path as necessary
import MainSection from './MainSection';
import '@rainbow-me/rainbowkit/styles.css'; // Adjust the path as necessary

function App() {
  return (
    <Providers>
      <MainSection />
    </Providers>
  );
}

export default App;
