import React, { useState, useEffect, useCallback } from 'react';
import { useAccount, useWalletClient } from 'wagmi';
import { formatUnits, parseUnits } from 'ethers';
import { getContract, createPublicClient, http } from 'viem';
import { avalanche } from 'viem/chains';
import {
  hundredContractAddress, hundredAbi
} from './abi/hundredContractConfig';
import {
  millionContractAddress, millionAbi
} from './abi/millionContractConfig';
import {
  stakingContractAddress, stakingAbi
} from './abi/stakingContractConfig';
import {
  nftContractAddress, nftAbi
} from './abi/nftContractConfig';
import styles from './styles/page.module.css';

const publicClient = createPublicClient({
  chain: avalanche,
  transport: http()
});

const StakingActions = ({ showStake, showMintTicket, showMintNFTFromStaking, showUnstake }) => {
  const { address: userAddress } = useAccount();
  const { data: walletClient } = useWalletClient();
  const [hundredContract, setHundredContract] = useState(null);
  const [stakingContract, setStakingContract] = useState(null);
  const [millionContract, setMillionContract] = useState(null);
  const [nftContract, setNftContract] = useState(null);
  const [stakeAmount, setStakeAmount] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);
  const [isStaked, setIsStaked] = useState(false);
  const [hundredBalance, setHundredBalance] = useState(0);
  const [canMint, setCanMint] = useState(false);
  const [nftsMintedThisPeriod, setNftsMintedThisPeriod] = useState(0);
  const [totalNftsMinted, setTotalNftsMinted] = useState(0);

  useEffect(() => {
    if (walletClient && hundredContractAddress && stakingContractAddress && millionContractAddress && nftContractAddress) {
      setHundredContract(getContract({
        address: hundredContractAddress,
        abi: hundredAbi,
        client: walletClient,
      }));
      setStakingContract(getContract({
        address: stakingContractAddress,
        abi: stakingAbi,
        client: walletClient,
      }));
      setMillionContract(getContract({
        address: millionContractAddress,
        abi: millionAbi,
        client: walletClient,
      }));
      setNftContract(getContract({
        address: nftContractAddress,
        abi: nftAbi,
        client: walletClient,
      }));
    }
  }, [walletClient]);

  // Fetch user's HundredToken balance
  const fetchHundredBalance = useCallback(async () => {
    if (!hundredContract || !userAddress) return;

    try {
      const balance = await hundredContract.read.balanceOf([userAddress]);
      const formattedBalance = parseFloat(formatUnits(balance, 18)).toFixed(2);
      setHundredBalance(formattedBalance);
      console.log('User HundredToken balance:', formattedBalance);
    } catch (error) {
      console.error('Error fetching HundredToken balance:', error);
    }
  }, [hundredContract, userAddress]);

  useEffect(() => {
    fetchHundredBalance();
  }, [fetchHundredBalance]);

  // Combined function to fetch stake info and update minting state
  const updateNftMintingState = useCallback(async () => {
    if (!stakingContract || !userAddress) return;

    try {
      // Fetch accrued rewards using the correct method
      const accruedRewardsRaw = await stakingContract.read.getAccruedRewardTokens([userAddress]);
      const accruedRewards = parseFloat(formatUnits(accruedRewardsRaw, 18));

      // Fetch stake information for the user
      const stakeInfo = await stakingContract.read.stakes([userAddress]);
      const firstMintTime = Number(stakeInfo[5]);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const correctResetPeriod = 2592000; // 30 days reset period for testing

      // Check if mintedThisPeriod needs to be reset
      let mintedThisPeriod = Number(stakeInfo[4]);
      if (firstMintTime === 0 || (firstMintTime > 0 && currentTime >= firstMintTime + correctResetPeriod)) {
        console.log("Resetting NFTs minted this period to 0 after the correct reset period.");
        mintedThisPeriod = 0;
      }
      setNftsMintedThisPeriod(mintedThisPeriod);

      // Fetch total NFTs minted
      const totalMinted = Number(stakeInfo[7]);
      setTotalNftsMinted(totalMinted);

      console.log("Accrued Rewards:", accruedRewards);
      console.log("Total NFTs Minted:", totalMinted);
      console.log("NFTs Minted This Period:", mintedThisPeriod);
      console.log("Is User Staked:", isStaked);
      console.log("First Mint Time:", firstMintTime);

      // Check if user can mint based on conditions
      const canMintEligibility = accruedRewards >= 100000 && totalMinted < 10 && mintedThisPeriod < 2 && isStaked;
      setCanMint(canMintEligibility);
      console.log("Minting eligibility updated - Can Mint:", canMintEligibility ? 'Yes' : 'No');

    } catch (error) {
      console.error('Error updating NFT minting state:', error);
    }
  }, [stakingContract, userAddress, isStaked]);


  useEffect(() => {
    updateNftMintingState();
  }, [updateNftMintingState]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateNftMintingState();
    }, 10000);

    return () => clearInterval(interval);
  }, [updateNftMintingState]);

  const checkIfStaked = useCallback(async () => {
    if (!stakingContract || !userAddress) return;

    try {
      const [isStakeholder] = await stakingContract.read.isStakeholder([userAddress]);
      setIsStaked(isStakeholder);
      console.log('User staking status:', isStakeholder);

    } catch (error) {
      console.error('Error checking staking status:', error);
      setIsStaked(false);
    }
  }, [stakingContract, userAddress]);

  useEffect(() => {
    checkIfStaked();
  }, [checkIfStaked]);

  const handleStakeClick = () => {
    setMessage('');
    setIsStakeModalOpen(true);
  };

  const handleConfirmStake = async () => {
    setIsStakeModalOpen(false);
    if (!hundredContract || !stakingContract || !userAddress || !stakeAmount) return;

    try {
      const amountInWei = parseUnits(stakeAmount, 18);
      await hundredContract.write.approve([stakingContractAddress, amountInWei]);
      setMessage(`You're approved now wait to confirm staking...`);
      setShowMessage(true);

      setTimeout(async () => {
        try {
          await stakingContract.write.stake([amountInWei], { gasLimit: 500000 });
          setMessage('Stake successful!');
          setShowMessage(true);
          setIsStaked(true);
        } catch (stakingError) {
          console.error('Error during staking:', stakingError);
          setMessage('Error during staking. Please try again.');
          setShowMessage(true);
        }
      }, 10000);

    } catch (error) {
      console.error('Error during approval:', error);
      setMessage('Error during approval. Please try again.');
      setShowMessage(true);
    }
  };

  const handleMintTicketClick = async () => {
    console.log("Mint Ticket button clicked");
    if (!nftContract || !userAddress || !millionContract) return;

    try {
      const userBalance = await millionContract.read.balanceOf([userAddress]);
      const requiredAmount = parseUnits("100000", 18);

      const formattedBalance = parseFloat(formatUnits(userBalance, 18)).toFixed(2);

      if (Number(userBalance) < Number(requiredAmount)) {
        setMessage(`You need at least 100,000 Million tokens to mint an NFT. Your current balance is ${formattedBalance} MILLION tokens.`);
        setShowMessage(true);
        return;
      }

      await millionContract.write.approve([nftContractAddress, requiredAmount]);
      setTimeout(async () => {
        try {
          await nftContract.write.mintTicket([userAddress], { gasLimit: 500000 });
          const openseaLink = `https://opensea.io/${userAddress}`;
          setMessage(`NFT minted successfully! Please connect your wallet on OpenSea to view your NFT. <a href="${openseaLink}" target="_blank" rel="noopener noreferrer">Go to OpenSea</a>`);
          setShowMessage(true);
        } catch (mintError) {
          console.error("Error during minting:", mintError);
          setMessage("Error during minting. Please try again.");
          setShowMessage(true);
        }
      }, 10000);

    } catch (error) {
      console.error("Error during approval or minting process:", error);
      setMessage("Error during approval or minting. Please try again.");
      setShowMessage(true);
    }
  };

  const handleClaimAndMintClick = async () => {
    console.log("Mint button clicked - handleClaimAndMintClick");
    console.log("User address:", userAddress);
    console.log("Staking Contract Object:", stakingContract);
    console.log("Can Mint:", canMint);
    console.log("isStaked:", isStaked);
    if (!stakingContract || !userAddress || !canMint) return;

    try {
      await stakingContract.write.claimAndMint([], { gasLimit: 500000 });
      setNftsMintedThisPeriod(prev => prev + 1);
      setTotalNftsMinted(prev => prev + 1);

      setTimeout(() => {
        const openseaLink = `https://opensea.io/${userAddress}`;
        setMessage(`NFT minted successfully from staking! Please connect your wallet on OpenSea to view your NFT. <a href="${openseaLink}" target="_blank" rel="noopener noreferrer">Go to OpenSea</a>`);
        setShowMessage(true);
    }, 10000);

    } catch (error) {
      console.error("Error during minting from staking:", error);
      if (error.message.includes("You must have an active stake to mint an NFT")) {
        setMessage("Error: You must have an active stake to mint an NFT. Please stake your tokens first.");
      } else {
        setMessage("Error during minting from staking. Please try again.");
      }
      setShowMessage(true);
    }
  };

  const handleUnstakeClick = async () => {
    if (!stakingContract || !userAddress) return;

    try {
      await stakingContract.write.unstake();
      setMessage('Unstaking initiated, waiting for confirmation...');
      setShowMessage(true);

      setTimeout(() => {
        setMessage('Unstake successful!');
        setShowMessage(true);
        setIsStaked(false);

        fetchHundredBalance();
        checkIfStaked();
      }, 10000);

    } catch (error) {
      console.error('Error during unstaking process:', error);
      setMessage("Error during unstaking process. Please try again.");
      setShowMessage(true);
    }
  };

  const handleDismissMessage = () => {
    setShowMessage(false);
  };

  return (
    <div className={styles.claimButtonContainer}>
      {showMintTicket && (
        <div className={styles.mintButtonWrapper}>
          <button
            onClick={handleMintTicketClick}
            className={`${styles.mintButton} ${styles.invisibleButton}`}
          ></button>
        </div>
      )}

      {(showStake || showMintNFTFromStaking || showUnstake) && (
        <div className="stakingPage">
          {isStaked && showStake && (
            <div className="animationContainer">
              <img
                src="/images/site/confetti.gif"
                alt="Staking Animation"
                style={{
                  position: 'absolute',
                  top: '18%',
                  left: '15%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: '450px',
                  zIndex: '10',
                  pointerEvents: 'none'
                }}
              />
            </div>
          )}

          {/* Minting Eligibility Display */}
          <div className={styles.mintEligibility}>
            Time to Mint? {canMint ? 'Yes! 😁' : 'No 😔'}
          </div>

          <div className={styles.buttonWrapper}>
            {showStake && (
              <button
                onClick={handleStakeClick}
                className={`${styles.stakeButton} ${styles.invisibleButton}`}
                disabled={!userAddress}
              ></button>
            )}
            {showMintNFTFromStaking && (
              <button
                onClick={handleClaimAndMintClick}
                className={`${styles.stakeMintButton} ${styles.invisibleButton}`}
                disabled={!userAddress || !canMint}
              ></button>
            )}
            {showUnstake && (
              <button
                onClick={handleUnstakeClick}
                className={`${styles.unstakeButton} ${styles.invisibleButton}`}
                disabled={!userAddress}
              ></button>
            )}
          </div>
        </div>
      )}

      {showMessage && (
        <div className={styles.messageOverlay}>
          <div className={styles.messageBox}>
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
            <button onClick={handleDismissMessage} className={styles.closeButton}>Close</button>
          </div>
        </div>
      )}

      {isStakeModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Enter Stake Amount</h2>
            <p className={styles.hundoBalance}> HUNDRED Balance: {parseFloat(hundredBalance).toFixed(2)}</p>
            <input
              type="number"
              value={stakeAmount}
              onChange={(e) => setStakeAmount(e.target.value)}
              placeholder="Enter amount to stake"
              className={styles.stakeInput}
            />
            <button onClick={handleConfirmStake} className={styles.confirmButton}>Confirm Stake</button>
            <button onClick={() => setIsStakeModalOpen(false)} className={styles.cancelButton}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakingActions;

export const handleMintTicketClick = async (userAddress, nftContract, millionContract, setMessage, setShowMessage) => {
  console.log("Mint Ticket button clicked");
    if (!nftContract || !userAddress || !millionContract) return;

    try {
      const userBalance = await millionContract.read.balanceOf([userAddress]);
      const requiredAmount = parseUnits("100000", 18);

      const formattedBalance = parseFloat(formatUnits(userBalance, 18)).toFixed(2);

      if (Number(userBalance) < Number(requiredAmount)) {
        setMessage(`You need at least 100,000 Million tokens to mint an NFT. Your current balance is ${formattedBalance} MILLION tokens.`);
        setShowMessage(true);
        return;
      }

      await millionContract.write.approve([nftContractAddress, requiredAmount]);
      setTimeout(async () => {
        try {
          await nftContract.write.mintTicket([userAddress], { gasLimit: 500000 });
          const openseaLink = `https://opensea.io/${userAddress}`;
          setMessage(`NFT minted successfully! Please connect your wallet on OpenSea to view your NFT. <a href="${openseaLink}" target="_blank" rel="noopener noreferrer">Go to OpenSea</a>`);
          setShowMessage(true);
        } catch (mintError) {
          console.error("Error during minting:", mintError);
          setMessage("Error during minting. Please try again.");
          setShowMessage(true);
        }
      }, 10000);

    } catch (error) {
      console.error("Error during approval or minting process:", error);
      setMessage("Error during approval or minting. Please try again.");
      setShowMessage(true);
    }
  };

